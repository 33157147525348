import { v4 as uuidv4 } from 'uuid'
import rayAvatar from '../../assets/images/ray.png'

const salesAgents = [
  {
    name: 'Raymond Wojtala',
    position: 'Senior Account Manager',
    phone: '+48 537 137 137',
    email: 'hello@10clouds.com',
    avatar: rayAvatar,
    uuid: uuidv4(),
  },
]

export default salesAgents
