import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import Layout from '../../features/layout'
import { NavLinkButton } from '../../components/Link'
import { externalContentStyle, breakpoints } from '../../../constants/style'
import { useCareer } from 'hooks/graphql/page'
import CareerHero from './components/CareerHero'
import { addBlankToUrls } from '../../utils/html'
import { POSITION_PLACEHOLDER } from './constants'
import { extractSalaryFromBenefits } from '../../components/sections/JobOffers/utils/jobUtils'
import 'hooks/graphql/others'
import { SEO } from 'components/Seo'
import AnnouncementTopBar from '../../components/AnnouncementTopBar'
import { getFirstPath } from 'utils/link'
import IconLoading from '../../components/icons/IconLoading'

export const query = graphql`
  query CareerPageQuery($id: String!) {
    ...workableJob

    sanityCommonSections(language: { eq: "en" }) {
      announcementTopBar {
        isAnnouncementTopBarDisplayed
        _rawAnnouncementTopBarShort
        _rawAnnouncementTopBarLong
        slugsList
      }
    }
  }
`

const StyledCareerAdWrapper = styled('section')`
  background-color: #f6f6f6;
  padding-bottom: 5rem;
  * {
    font-family: 'BioSans-Regular', 'Roboto-Regular', sans-serif;
  }

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  .career-ad__content {
    background: white;
    width: 70%;
    padding: 5rem;
    margin: 0 auto;
    position: relative;
    margin-top: -10rem;
    ${externalContentStyle};

    h1,
    h2,
    h3 {
      margin-top: 0;
      margin-bottom: 1.5rem;
    }

    p,
    ul,
    ol {
      margin-bottom: 1.5rem;
    }

    p,
    li {
      font-size: 1rem;
    }

    li {
      display: flex;
    }
  }

  @media only screen and (max-width: ${breakpoints.desktopLarge}) {
    .career-ad__content {
      width: 100%;
    }
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    .career-ad__content {
      margin-top: -7rem;
    }
  }

  @media only screen and (max-width: ${breakpoints.mobile}) {
    padding-bottom: 2rem;
    .career-ad__content {
      padding: 1rem;
      margin-top: -10rem;
    }
  }
`

const CareerPage = ({ data, location: browserLocation, pageContext }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [jobData, setJobData] = useState(null)
  const { sanityCommonSections } = data
  const { id } = pageContext
  const {
    careerDictionary: { applyLabel },
  } = useCareer()

  useEffect(() => {
    const getCurrentJobData = async () => {
      setIsLoading(true)
      fetch(`/api/get-traffit-data?id=${id}`, {
        method: 'GET',
      })
        .then((r) => r.json())
        .then((response) => {
          if (response?.length > 0) {
            setJobData(response[0])
          }
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
    getCurrentJobData()
  }, [])

  if (!id || !jobData) return null

  const {
    _description: description,
    _requirements: requirements,
    _benefits: benefits,
    name: title,
    applicationFormLink,
    advertDescription,
  } = jobData
  const location = {
    country: jobData?._country,
    city: jobData?._city,
    telecommuting: jobData?._telecommuting,
    overrideLocationLabel: jobData?._override_location_label,
  }

  const {
    announcementTopBar: {
      isAnnouncementTopBarDisplayed,
      _rawAnnouncementTopBarLong,
      _rawAnnouncementTopBarShort,
      slugsList,
    },
  } = sanityCommonSections

  const announcementTopBarData = {
    isDisplayed: isAnnouncementTopBarDisplayed,
    longAnnouncement: _rawAnnouncementTopBarLong,
    shortAnnouncement: _rawAnnouncementTopBarShort,
  }

  const currentLocation = browserLocation.pathname

  // keeping for the in-house apply form pages
  // const applyFormLink = `${currentLocation}apply/`

  const isAnnouncementForCareers = slugsList?.includes('careers')

  return (
    <Layout>
      {isAnnouncementForCareers && (
        <AnnouncementTopBar
          announcementData={announcementTopBarData}
          pageName={getFirstPath(currentLocation)}
        />
      )}
      <CareerHero title={title} location={location} />

      <StyledCareerAdWrapper>
        {isLoading ? (
          <div className="loading">
            <IconLoading />
          </div>
        ) : (
          <div className="career-ad__content">
            {advertDescription ? (
              <div dangerouslySetInnerHTML={{ __html: addBlankToUrls(advertDescription) }}></div>
            ) : (
              <>
                {description && (
                  <div dangerouslySetInnerHTML={{ __html: addBlankToUrls(description) }}></div>
                )}
                {requirements && (
                  <div dangerouslySetInnerHTML={{ __html: addBlankToUrls(requirements) }}></div>
                )}
                {benefits && (
                  <div dangerouslySetInnerHTML={{ __html: addBlankToUrls(benefits) }}></div>
                )}
              </>
            )}
            <NavLinkButton to={applicationFormLink} variant="filled">
              {applyLabel}
            </NavLinkButton>
          </div>
        )}
      </StyledCareerAdWrapper>
    </Layout>
  )
}

export function Head({ location: browserLocation, pageContext }) {
  const {
    careerAdPage: { seoDescription },
  } = useCareer()

  const { job } = pageContext

  if (!job) return null

  const {
    _description: description,
    _benefits: benefits,
    name: title,
    created_at,
    job_type: employment_type,
  } = job
  const location = {
    country: job?._country,
    city: job?._city,
    telecommuting: job?._telecommuting,
  }

  const indexOfNetBenefits = extractSalaryFromBenefits(benefits)?.indexOf('net')
  const salaryRange = extractSalaryFromBenefits(benefits)?.slice(4, indexOfNetBenefits)
  const twoMontsAdvancedCreatedAtDate = new Date(
    new Date(created_at).setMonth(new Date(created_at).getMonth() + 2),
  ).toDateString()
  const indexOfDotDescription = description?.indexOf('.')
  const extractedDescription = description?.slice(3, indexOfDotDescription)
  const seoImage = { asset: { url: 'https://10clouds.com/images/hiring-seo-image.jpg' } }

  return (
    <SEO
      location={browserLocation}
      title={title}
      description={seoDescription.replace(POSITION_PLACEHOLDER, title)}
      image={seoImage}
    >
      {/* https://developers.google.com/search/docs/advanced/structured-data/job-posting */}
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'https://schema.org/',
          '@type': 'JobPosting',
          title: title,
          description: `<p>${extractedDescription}</p>`,
          identifier: {
            '@type': 'PropertyValue',
            name: '10Clouds',
            value: '1234567',
          },
          datePosted: created_at,
          validThrough: twoMontsAdvancedCreatedAtDate,
          applicantLocationRequirements: {
            '@type': 'Country',
            name: location._country,
          },
          jobLocationType: 'TELECOMMUTE',
          employmentType: employment_type,
          hiringOrganization: {
            '@type': 'Organization',
            name: '10Clouds',
            sameAs: 'http://10clouds.com',
            logo: 'https://10clouds.com//favicon-32x32.png?v=49a3c48f999c570021459142bc30db90',
          },
          baseSalary: {
            '@type': 'MonetaryAmount',
            currency: 'PLN',
            value: {
              '@type': 'QuantitativeValue',
              value: salaryRange,
              unitText: 'MONTH',
            },
          },
        })}
      </script>
    </SEO>
  )
}

export default CareerPage
