import React from 'react'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import { useCareer } from 'hooks/graphql/page'
import { colors } from '../../../../constants/style'
import { convertToBackgroundImage } from '../../../utils/image'
import { talentPoolTitle } from '../../../components/sections/JobOffers/constants'

const StyledCareerHero = styled(BackgroundImage)`
  min-height: 100vh;
  color: white;
  background-color: black;
  position: relative;

  .career-ad-hero__text-wrapper {
    /* for some weird reason ie11 didn't want to center text with flex */
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 100%;
    text-align: center;
  }
  .career-ad-hero__title {
    font-size: 3rem;
  }
  .career-ad-hero__location {
    color: ${colors.mutedLighter};
    font-size: 1.3rem;
  }
`

const CareerHero = ({ title, location, children }) => {
  // chldren is used only on thank you subpage for each offer
  const {
    careerAdPage: { heroImage },
    careerDictionary: { remoteLabel, remoteOnlyLabel },
  } = useCareer()

  const getLocationLabel = ({
    city,
    telecommuting,
    country,
    location_str,
    overrideLocationLabel,
  }) => {
    if (overrideLocationLabel) return overrideLocationLabel

    // telecommuting this means it's Fully Remote - in workable - Paulina Wojtyla confirmed
    // also https://resources.workable.com/hr-terms/what-is-telecommuting
    if (location_str && !telecommuting) return location_str

    if (
      country !== 'Poland' &&
      country !== undefined &&
      (telecommuting || (!telecommuting && !city))
    ) {
      return `Remote (Europe)`
    }

    if (country !== 'Poland' && country !== undefined) {
      return country
    }
    if (!city && telecommuting) {
      return remoteOnlyLabel
    }
    if (city && !telecommuting) {
      return city
    }
    if (city && telecommuting) {
      return remoteLabel
    }

    return remoteLabel
  }
  const locationLabel = getLocationLabel(location)
  const bgImage = convertToBackgroundImage(heroImage?.asset)

  const backgroundStack = [
    `linear-gradient(90deg,rgba(0,0,0,.85),rgba(13,2,37,.85))`,
    bgImage?.fluid,
  ]
  const isTalentPool = title.includes(talentPoolTitle) ? null : (
    <p className="career-ad-hero__location">{locationLabel}</p>
  )
  const isTalentPoolTitle = title.includes(talentPoolTitle) ? 'Apply to 10Clouds' : title

  return (
    <StyledCareerHero Tag="section" fluid={backgroundStack}>
      {children || (
        <div className="career-ad-hero__text-wrapper">
          <h1 className="career-ad-hero__title">{isTalentPoolTitle}</h1>
          {isTalentPool}
        </div>
      )}
    </StyledCareerHero>
  )
}

export default CareerHero
